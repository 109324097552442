import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "Bing Bonk",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative pb-8 bg-body overflow-hidden" style={{}}>
          <img
            className="absolute top-0 right-0"
            src="casper-assets/headers/circle-double-element-dark.svg"
            alt=""
          />
          <div className="relative py-10">
            <img
              className="hidden sm:block absolute bottom-0 right-0 xl:mr-20 md:mb-10 animate-spinStar"
              src="casper-assets/headers/blink.png"
              alt=""
            />
            <img
              className="hidden sm:block absolute top-0 left-0 md:ml-16 mt-4 animate-spinStar"
              src="casper-assets/headers/blink-sm.png"
              alt=""
            />
            <div className="container mx-auto px-4">
              <div className="text-center">
                <h1 className="font-heading text-6xl md:text-8xl xl:text-9xl font-semibold text-white tracking-tight">
                  <span>
                    <span className="text-red-800">!</span>
                    Bing Bonk
                    <span className="text-red-800">!</span>
                  </span>
                </h1>
                <a
                  href="https://explorer.solana.com/address/51PUhVnRaJp8AnWx3hVwD5HByjmSbSAK3r6qtcj67CLY"
                  target="_blank"
                >
                  <h2 className="font-heading text-xl md:text-2xl xl:text-3xl font-semibold text-white tracking-tight">
                    CA: 51PUhVnRaJp8AnWx3hVwD5HByjmSbSAK3r6qtcj67CLY
                  </h2>
                </a>
              </div>
            </div>
          </div>
          <div className="relative container mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                <div
                  className="group block max-w-md mx-auto lg:max-w-none bg-radial to-indigo-900 from-blueGray-800 rounded-3xl overflow-hidden"
                  href="#"
                >
                  <div className="relative h-148 bg-opacity-0 bg-blueGray-800 group-hover:bg-opacity-100 transition duration-500">
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <img
                        className="h-96 transform group-hover:scale-105 transition duration-300"
                        src="img/bingbonk.png"
                        alt=""
                      />
                    </div>
                    <div
                      className="relative flex flex-col p-10 h-full"
                      contentEditable="false"
                    >
                      <span className="block text-5xl text-white font-medium">
                        Meet...
                      </span>
                      <span className="block mt-auto text-5xl text-white">
                        <span className="text-red-800">!</span>Bing Bonk
                        <span className="text-red-800">!</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                <a
                  className="group block max-w-md mx-auto lg:max-w-none bg-radial to-orange-800 from-orange-600 rounded-3xl overflow-hidden"
                  href="#"
                >
                  <div className="relative h-148 bg-opacity-0 bg-orange-600 group-hover:bg-opacity-100 transition duration-500">
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center px-4">
                      <img
                        className="h-96 transform group-hover:scale-105 transition duration-300"
                        src="img/sol.svg"
                        alt=""
                      />
                    </div>
                    <div className="relative flex flex-col p-10 h-full">
                      <span className="block text-5xl text-white font-medium">
                        Solana
                      </span>
                      <span className="block mt-auto text-4xl text-white">
                        Where the real
                      </span>
                      <span className="block text-4xl text-white">
                        community is
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full lg:w-1/3 px-4">
                <a
                  className="group block max-w-md mx-auto lg:max-w-none bg-radial to-teal-800 from-teal-600 rounded-3xl overflow-hidden"
                  href="https://raydium.io/swap/"
                  target="_blank"
                >
                  <div className="relative h-148 bg-opacity-0 bg-teal-600 group-hover:bg-opacity-100 transition duration-500">
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <img
                        className="h-96 px-4 transform group-hover:scale-105 transition duration-300"
                        src="img/ray.svg"
                        alt=""
                      />
                    </div>
                    <div className="relative flex flex-col p-10 h-full">
                      <span className="block text-5xl text-white font-medium">
                        Buy on Raydium
                      </span>
                      <span className="block mt-auto text-4xl text-white">
                        <a
                          className="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full"
                          href="https://raydium.io/swap/"
                          target="_blank"
                        >
                          <span className="mr-2">BUY ON RAYDIUM</span>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="absolute top-0 right-0 -mt-72 w-148 h-148 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl" />
            <div className="absolute top-0 right-0 mr-112 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl" />
            <div className="relative">
              <div className="max-w-4xl mx-auto text-center mb-16">
                <h2 className="font-heading text-4xl xs:text-5xl sm:text-7xl font-medium text-white tracking-tight mb-3">
                  Tokenomics
                </h2>
              </div>
              <div className="max-w-sm lg:max-w-none mx-auto">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full px-4 mb-10 pb-10 lg:mb-0 lg:pb-0 border-b lg:border-b-0 lg:border-r border-white border-opacity-20 lg:w-1/2">
                    <div className="flex items-center lg:justify-center">
                      <div className="ml-5 xl:ml-8">
                        <span className="block text-4xl xl:text-6xl font-semibold text-gray-100">
                          0/0
                        </span>
                        <span className="sm:text-lg text-xl tracking-tight text-gray-100">
                          Final Buy/Sell Tax
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-4 mb-10 pb-10 lg:mb-0 lg:pb-0 border-b lg:border-b-0 border-white border-opacity-20 lg:w-1/2">
                    <div className="flex items-center lg:justify-center">
                      <div className="ml-5 xl:ml-8">
                        <span className="block text-4xl xl:text-6xl font-semibold text-gray-100">
                          100M
                        </span>
                        <span className="sm:text-lg text-xl tracking-tight text-gray-100">
                          Total Supply
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <img
              className="hidden md:block absolute top-0 right-0 lg:left-1/2 -ml-20 xl:-ml-32 -mt-24 z-10 animate-spinStar"
              src="casper-assets/logo-clouds/blink-star.png"
              alt=""
            />
            <div className="absolute top-0 right-0 -mt-72 -mr-40 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl" />
            <div className="relative max-w-xl mx-auto lg:max-w-none">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/2 px-4 order-last lg:order-first">
                  {/* <div className="max-w-sm"> */}
                  <div className="flex flex-col -mx-4 -mb-12 justify-center">
                    <a href="https://www.dextools.io/app" target="_blank">
                      <div className="px-4 py-6">
                        <img
                          className="block mx-auto h-14 px-4"
                          src="img/dextools.png"
                          alt=""
                        />
                      </div>
                    </a>
                    <a href="Https://Twitter.com/BingBonkSOL" target="_blank">
                      <div className="px-4 py-6">
                        <img
                          className="block mx-auto h-14 px-4"
                          src="img/x.png"
                          alt=""
                        />
                      </div>
                    </a>
                    <a href="https://t.me/BingBonkSOL" target="_blank">
                      <div className="px-4 py-6">
                        <img
                          className="block mx-auto h-14 px-4"
                          src="img/telegram.png"
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  {/* </div> */}
                </div>
                <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
                  <div className="max-w-xl mx-auto lg:mr-0">
                    <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-8">
                      Join the Bing Bonk Community
                    </h2>
                    <p className="max-w-lg text-white tracking-tight text-xl">
                      BING BONK, Inspired by the great BONK. You cant Bing
                      without BONK. Rooting from the phrase Bing Bong- TO THE
                      MOON.
                    </p>
                    <br />
                    <p className="max-w-lg text-white tracking-tight text-xl">
                      “Bing bong” is a playful and informal term used in the
                      crypto community to describe the sound of price
                      fluctuations, especially sharp ups and downs in the
                      market. It doesn’t have a specific technical meaning but
                      is rather a humorous way to refer to the volatility in
                      cryptocurrency prices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative sm:pt-24 bg-body overflow-hidden py-6">
          <div className="relative container mx-auto px-4">
            <div className="absolute top-0 right-0 -mt-96 -mr-52 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
            <div className="relative">
              <div className="pt-10 border-t border-gray-800">
                <div className="md:flex items-center justify-between">
                  <div className="mb-6 md:mb-0 flex justify-between w-full text-xl">
                    <span className="text-gray-600 tracking-tight">
                      © Bing Bonk. All rights reserved - NFA. DYOR.{" "}
                    </span>
                    <span className="text-gray-600 tracking-tight">
                      <a href="mailto:contact@bingbonk.fi">
                        contact@bingbonk.fi
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
